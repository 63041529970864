// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/all')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})


export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})


export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('https://apiv3dev.opentlv.com/api/v3/users/adduser', user)
  await dispatch(getData(getState().users.params))
  //await dispatch(getAllData())
  return user
})

export const editUserInfos = createAsyncThunk('appUsers/editUserInfos', async (user, { dispatch, getState }) => {
  await axios.post('https://apiv3dev.opentlv.com/api/v3/users/edit/infos', user)
  await dispatch(getData(getState().users.params))
  //await dispatch(getAllData())
  return user
})

export const ChangeUserPassword = createAsyncThunk('appUsers/ChangeUserPassword', async (user, { getState }) => {
  console.log("user")
  console.log(getState().users.selectedUser.id)
  user.user_id = getState().users.selectedUser.id


  await axios.post('https://apiv3dev.opentlv.com/api/v3/user/changepassword', user)
 // await dispatch(getData(getState().users.params))
  //await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('https://apiv3dev.opentlv.com/api/v3/users/delete', {params: {id}})
  await dispatch(getData(getState().users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    secret : null,
    value : null 
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
    

  }
})

export default appUsersSlice.reducer
